<template>
  <v-sheet
    v-if="display"
    class="webinarDialog d-flex flex-column"
    v-model="display"
    :title="$t('Title')"
    @save="$emit('save')"
    :color="wsDARKER"
  >
    <!--  Header  -->
    <ws-webinar-room-header
      @close="display = false"
      :name="webinar.name"
    />

    <!-- Content-->
    <div class="flex-grow-1 pa-6">
      <div class="d-flex flex-row fill-height " style="column-gap: 16px">

        <div class="flex-grow-1 fill-height">
          <webinar-video-view user />
        </div>

        <!--    Block with navigation menu (right sidebar)    -->
        <v-sheet :color="wsDARKER" class="fill-height">
          <ws-right-sidebar user />
        </v-sheet>

      </div>

    </div>

    <!-- DIALOGS-->

    <!--   Webinar preview dialog   -->
<!--    <ws-webinar-preview-dialog-->
<!--      v-if="displayPreviewDialog"-->
<!--      v-model="displayPreviewDialog"-->
<!--      @exit-webinar="display = false"-->
<!--    />-->
  </v-sheet>

</template>

<script>

import WsWebinarRoomHeader from "@modules/webinars/components/student/webinar-room/UI/wsWebinarRoomHeader.vue";
import WebinarVideoView from "@modules/webinars/components/Webinar/admin/room/stream/webinarVideoView.vue";
import {mapActions} from "vuex";
import WsRightSidebar from "@modules/webinars/components/Webinar/admin/room/sidebars/wsRightSidebar.vue";

import WsMediaController from "@modules/webinars/plugins/wsMediaController";
import webinars from "@modules/webinars/mixins/webinars";


export default {
  name: "StudentWebinarRoom.vue",
  mixins: [webinars],
  components: {
    // WsWebinarPreviewDialog,
    WebinarVideoView,
    WsRightSidebar,
    WsWebinarRoomHeader
  },
  props: {
    value: {
      type: Boolean,
    },
    webinar: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      display : false,
      isActiveSidebarWindow: false,
      checkedValue: '',
      // displayPreviewDialog: true,

    }
  },
  computed: {
    loading() {
      return !this.WEBINAR.uuid
    }
  },
  methods: {
    ...mapActions('webinars',
      ['GET_ONE_WEBINAR']
    ),

    async getWebinarDetails() {
      this.$store.state.webinars.webinar = this.COPY(this.webinar)
      // const result = await this.GET_ONE_WEBINAR({ uuid: this.webinar.uuid } );
      //
      // if (!result) {
      //   this.ERROR();
      //   return
      // }
      //
      // this.$store.state.webinars.webinar = await result;
    },

    async initMediaController() {
      this.$store.state.stream.wsMediaController = new WsMediaController()
      this.$store.state.stream.isMediaReady = await this.MEDIA_CONTROLLER.init()
      console.log(this.MEDIA_CONTROLLER)
    },


  },
  async mounted() {

    await this.initMediaController()

    if ( this.value ) {
      this.display = this.value
    }

    await this.getWebinarDetails()
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('stream/FLUSH_VARIABLES')
  }
}
</script>

<style scoped>
.webinarDialog {
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

</style>
