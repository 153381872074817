<template>
  <v-sheet>
    <div
      v-for="(item, index) in items"
      :key="item.name + index"
      class="d-block rounded-lg"
      :style="`background: ${wsLIGHTCARD}; max-height: 240px;`"
    >
      <div
        class="d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-shrink justify-start mb-4 d-sm-block"
        style="width: 100%"
      >
        <div :style="displayShow('sm') ? 'width: 100%;' : 'width: 400px; height: 240px'">
          <img
            :style="displayShow('sm') ? 'width: 100%; border-top-right-radius: 16px; border-bottom-right-radius: 16px' : 'width: 100%; height: 100%; object-fit: cover'"
            :src="item.image && item.image.trim() !== '' ? item.image : require(`@/modules/webinars/assets/no_student_webinar.png`)"
            alt="webinar image"
          >
        </div>

        <div class="pa-4" style="overflow: hidden; width: 100%">
          <h3 class="mb-1 title" :style="`color: ${wsACCENT}`">{{ item.name }}</h3>
          <p class="mb-1" :style="`color: ${wsACCENT}`">Автор: {{ item.description }}</p>
          <p class="mb-1" :style="`color: ${wsACCENT}`">Дата вебінару: {{ item.date }}</p>
          <p class="mb-9" :style="`color: ${wsACCENT}`">Доступ до кімнати через: <span style="font-weight: 600">{{ item.time }}</span></p>
          <ws-button :label="$t('webinar.student.join')" icon="mdi-chevron-right" @click="$emit('open-webinar', item)"/>
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "wsWebinarItemsList",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.title {
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
